<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('Full Name') }}</th>
                    <th>{{ $t('Nationality') }}</th>
                    <th
                      style="width: 200px"
                      class="text-center"
                    >
                      <v-btn
                        depressed
                        color="primary"
                        small
                        @click="addResidence()"
                      >
                        <v-icon
                          class="mdil-24px"
                          left
                        >
                          mdil-plus
                        </v-icon>
                        {{ $t('Add New') }}
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in companyResidences"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.full_name }}</td>
                    <td>{{ row.nationality }}</td>
                    <td class="text-center">
                      <v-btn
                        color="info"
                        small
                        text
                        depressed
                        @click="viewResidence(row)"
                      >
                        <v-icon left>
                          mdi-eye
                        </v-icon>
                        {{ $t('View') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <add-residence
      :add-dialog="addDialog"
      :form-data="formData"
      @close="addDialog = false"
      @reset-table-data="getCompanyResidences()"
    />

    <show-residence
      :id="id"
      :show-dialog="showDialog"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddResidence from './AddResidence.vue'
import ShowResidence from './ShowResidence.vue'

export default {
  components: {
    AddResidence,
    ShowResidence,
  },

  data() {
    return {
      companyResidences: [],
      company: {},
      formData: {},
      id: 0,
      addDialog: false,
      showDialog: false,
    }
  },

  mounted() {
    this.getCompanyData().then(() => {
      this.getCompanyResidences()
    })
  },

  methods: {
    async getCompanyData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.company = res.data
      })
    },

    getCompanyResidences() {
      axios.get('residence/', { params: { company_id: this.company.id } }).then(res => {
        this.companyResidences = res.data
      })
    },

    addResidence() {
      this.formData = {
        company_id: this.company.id,
        full_name: null,
        nationality: null,
        residence_date: null,
        expire_date: null,
        profile_picture: null,
        residence_picture: null,
      }
      this.addDialog = true
    },

    viewResidence(row) {
      this.id = row.id
      this.showDialog = true
    }
  },
}
</script>

<style lang="scss" scoped></style>
